import { useState } from "react";
import "./App.css";
import Dashboard from "./Dashboard/Dashboard.component";
import Header from "./Header/Header.component";
import SearchForm from "./SearchForm/SearchForm.component";
import SearchResults from "./SearchResults/SearchResults.component";

function App() {
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [exclude, setExclude] = useState(false);
  const amenities = [
    {
      name: "hot tub",
      id: 25,
    },
    {
      name: "pool",
      id: 7,
    },
  ];

  const handleSearch = (results) => {
    setSearchResults(results);
    setFilteredResults(results);
  };

  const onFilterChange = (checkedAmenities) => {
    let filteredResults;
    if (checkedAmenities.length === 0) {
      setFilteredResults(searchResults);
      return;
    }

    if (exclude === true) {
      filteredResults = searchResults.filter((result) => {
        return !checkedAmenities.some((amenity) =>
          result.amenityIds.includes(amenity)
        );
      });
    } else {
      filteredResults = searchResults.filter((result) => {
        return checkedAmenities.every((amenity) =>
          result.amenityIds.includes(amenity)
        );
      });
    }

    setFilteredResults(filteredResults);
  };

  return (
    <div className="form-group">
      <Header />
      <SearchForm onSearch={handleSearch} />

      <div className="results-container">
        {searchResults.length > 0 && (
          <Dashboard searchResults={filteredResults} />
        )}
        <SearchResults
          results={filteredResults}
          amenities={amenities}
          onFilterChange={onFilterChange}
          setExclude={setExclude}
          exclude={exclude}
          filteredResults={filteredResults}
        />
      </div>
    </div>
  );
}

export default App;
