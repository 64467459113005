import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import bellcurve from "highcharts/modules/histogram-bellcurve";
import React from "react";
import { Bar } from "react-chartjs-2";
import "./Dashboard.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

bellcurve(Highcharts);

function removeOutliers(arr) {
  arr.sort(function (a, b) {
    return a - b;
  });

  var q1 = arr[Math.floor(arr.length / 4)];
  var q3 = arr[Math.ceil(arr.length * (3 / 4))];
  var iqr = q3 - q1;

  var min = q1 - 1.5 * iqr;
  var max = q3 + 1.5 * iqr;

  return arr.filter(function (x) {
    return x >= min && x <= max;
  });
}

function getBellCurveOptions(searchResults) {
  let data = searchResults.map((listing) =>
    Math.ceil(listing.nightlyRate.amount / listing.lengthOfStay)
  );

  let filteredData = removeOutliers(data);

  // Define the chart options
  const options = {
    title: {
      text: "Nightly Rates Price curve",
    },

    xAxis: [
      {
        title: {
          text: "Data",
        },
        alignTicks: false,
      },
      {
        title: {
          text: "Bell curve",
        },
        alignTicks: false,
        opposite: true,
      },
    ],

    yAxis: [
      {
        title: { text: "Nightly Rates" },
      },
      {
        title: { text: "Bell curve" },
        opposite: true,
      },
    ],

    series: [
      {
        name: "Bell curve",
        type: "bellcurve",
        xAxis: 1,
        yAxis: 1,
        baseSeries: 1,
        zIndex: -1,
      },
      {
        name: "Data",
        type: "scatter",
        data: filteredData,
        accessibility: {
          exposeAsGroupOnly: true,
        },
        marker: {
          radius: 1.5,
        },
      },
    ],
  };

  return options;
}

function getBarChartData(searchResults) {
  const rates = searchResults.map((listing) =>
    Math.ceil(listing.nightlyRate.amount / listing.lengthOfStay)
  );

  const filteredRates = removeOutliers(rates);

  const minRate = Math.min(...filteredRates);
  const maxRate = Math.max(...filteredRates);

  const range = maxRate - minRate;
  const interval = range / 7;

  const labels = [];
  const dataPoints = [];

  for (let i = 0; i < 7; i++) {
    const start = Math.ceil(minRate + i * interval);
    const end = Math.ceil(start + interval);
    const label = `$${start}-${end}`;
    const count = filteredRates.filter(
      (rate) => rate >= start && rate <= end
    ).length;
    labels.push(label);
    dataPoints.push(count);
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Count",
        data: dataPoints,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return data;
}

function percentile(arr, n) {
  if (n <= 0 || n > 100) {
    throw new Error("Percentile must be between 0 and 100");
  }

  arr.sort(function (a, b) {
    return a - b;
  });

  var index = Math.ceil((n / 100) * arr.length) - 1;

  return arr[index];
}

function Dashboard({ searchResults }) {
  const rates = searchResults.map((listing) =>
    Math.ceil(listing.nightlyRate.amount / listing.lengthOfStay)
  );

  const filteredRatesNoOutliers = removeOutliers(rates);

  const percentile1 = percentile(filteredRatesNoOutliers, 25);
  const percentile2 = percentile(filteredRatesNoOutliers, 50);
  const percentile3 = percentile(filteredRatesNoOutliers, 75);
  const percentile4 = percentile(filteredRatesNoOutliers, 99);

  const data = getBarChartData(searchResults);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Rate Distribution",
      },
    },
  };
  const options2 = getBellCurveOptions(searchResults);

  return (
    <>
      <div className="percentile-cards">
        <div className="percentile-card">
          <div>25th Percentile</div>
          <div>${percentile1}</div>
        </div>
        <div className="percentile-card">
          <div>50th Percentile</div>
          <div>${percentile2}</div>
        </div>
        <div className="percentile-card">
          <div>75th Percentile</div>
          <div>${percentile3}</div>
        </div>
        <div className="percentile-card">
          <div>99th Percentile</div>
          <div>${percentile4}</div>
        </div>
      </div>
      <div className="graph-container">
        <HighchartsReact highcharts={Highcharts} options={options2} />
        <Bar options={options} data={data} />
      </div>
    </>
  );
}

export default Dashboard;
