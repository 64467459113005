import { useState } from "react";
import favorite from "../images/favorite.svg";
import {
  fetchAllAirbnbByLocation,
  fetchPagedAirbnbByLocation,
} from "../Services/RapidApiAirbnb";
import SidebarNav from "../Sidebar/sidebar.component";
import Spinner from "../Spinner/spinner.component";
import "./searchform.styles.css";
import "./searchheader.styles.css";

function SearchForm({ onSearch }) {
  const [location, setLocation] = useState("Austin");
  const [checkin, setCheckin] = useState(getToday());
  const [checkout, setCheckout] = useState(getTomorow());
  const [adults, setAdults] = useState(10);
  const [page, setPage] = useState(1);
  const [minBedrooms, setMinBedrooms] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSavedSearches = () => {
    setIsOpen(!isOpen);
  };

  function handleSetters(locationValue, adultsValue, minBedroomsValue) {
    setLocation(locationValue);
    setAdults(adultsValue);
    setMinBedrooms(minBedroomsValue);
    toggleSavedSearches();
    handleSubmit();
  }

  function getToday() {
    // updated this to tomorrow days to avoid hour issues
    const today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const offset = tomorrow.getTimezoneOffset();
    tomorrow = new Date(tomorrow.getTime() - offset * 60 * 1000);
    return tomorrow.toISOString().split("T")[0];
  }

  function getTomorow() {
    // updated this to 2 days to avoid hour issues
    const today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 2);
    const offset = tomorrow.getTimezoneOffset();
    tomorrow = new Date(tomorrow.getTime() - offset * 60 * 1000);
    return tomorrow.toISOString().split("T")[0];
  }

  const handleSubmit = () => {
    setIsLoading(true);
    fetchAllAirbnbByLocation(
      location,
      checkin,
      checkout,
      adults,
      page,
      minBedrooms
    ).then((response) => {
      console.log(response);
      setIsLoading(false);
      if (!response.error) {
        onSearch(response.results);
      } else {
        // handle the error
      }
    });
  };

  const handleSubmitByPage = () => {
    setIsLoading(true);
    fetchPagedAirbnbByLocation(
      location,
      checkin,
      checkout,
      adults,
      page,
      minBedrooms
    ).then((response) => {
      setIsLoading(false);
      if (!response.error) {
        onSearch(response.results);
      } else {
        // handle the error
      }
    });
  };

  return (
    <>
      <SidebarNav
        isOpen={isOpen}
        toggleSavedSearches={toggleSavedSearches}
        handleSetters={handleSetters}
      />

      <div className="search-container">
        {isLoading ? <Spinner /> : null}

        <h2 className="search-header">Search Airbnb Supply</h2>
        <div className="search-form">
          <label>
            <input
              placeholder="page"
              type="text"
              value={page}
              onChange={(e) => setPage(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              placeholder="location"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              className="form-control"
              type="date"
              value={checkin}
              onChange={(e) => setCheckin(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              className="form-control"
              type="date"
              value={checkout}
              onChange={(e) => setCheckout(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              type="text"
              placeholder="min bedrooms"
              className="form-control"
              value={minBedrooms}
              onChange={(e) => setMinBedrooms(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              placeholder="number of guests"
              type="number"
              className="form-control"
              value={adults}
              onChange={(e) => setAdults(e.target.value)}
            />
          </label>
          <br />
          <div className="button-container">
            <button className="secondary-button" onClick={toggleSavedSearches}>
              <img width="20px" src={favorite} alt="My Saved Searches" />
            </button>

            <button className="btn" onClick={handleSubmitByPage}>
              Search Page
            </button>

            <button className="btn" onClick={handleSubmit}>
              Search All
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchForm;
