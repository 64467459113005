import React from "react";
import "./sidebar.styles.css";

function SidebarNav({ isOpen, handleSetters, toggleSavedSearches }) {
  return (
    <>
      {isOpen && (
        <div className="sidebar-nav sidebar-container">
          <div className="logo">AirSupply</div>
          <div className="saved-searches">
            <h4>My Saved Searches:</h4>
            <div
              className="search-item"
              onClick={() =>
                handleSetters("4107 Tablerock Dr, Austin, TX 78731", 12, 5)
              }
            >
              Tablerock
            </div>
            <div
              className="search-item"
              onClick={() =>
                handleSetters("7106 S Ute Trl, Austin, TX 78729", 12, 4)
              }
            >
              Ute
            </div>
            <div
              className="search-item"
              onClick={() =>
                handleSetters("1611 Elmira Rd, Austin, TX 78721", 12, 4)
              }
            >
              Elmira
            </div>
            <div
              className="search-item"
              onClick={() =>
                handleSetters("3017 E 14th St, Austin, TX 78702", 10, 4)
              }
            >
              E 14th
            </div>
            <div
              className="search-item"
              onClick={() =>
                handleSetters("8324 Autumncrest Ct, Dallas, TX 75249", 16, 5)
              }
            >
              Autumncrest
            </div>

            <button className="btn" onClick={toggleSavedSearches}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SidebarNav;
