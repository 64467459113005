import React from "react";
import Filter from "../Filter/Filter.component";
import starRating from "./../images/star_rating.svg";
import "./SearchResults.css";

function SearchResults(props) {
  const {
    results,
    amenities,
    onFilterChange,
    setExclude,
    exclude,
    filteredResults,
  } = props;

  // Create an array of all the rates in the results
  const rates = results.map(
    (listing) => listing.nightlyRate.amount / listing.lengthOfStay
  );

  // Determine the minimum and maximum rates
  const minRate = Math.min(...rates);
  const maxRate = Math.max(...rates);

  // Calculate a "price range factor" based on the difference between the minimum and maximum rates
  const rangeFactor = maxRate - minRate;

  return (
    <>
      {results.length > 0 ? (
        <Filter
          amenities={amenities}
          onFilterChange={onFilterChange}
          setExclude={setExclude}
          exclude={exclude}
          filteredResults={filteredResults}
        />
      ) : null}
      <div className="dashboard-container">
        {results.map((listing, index) => {
          // Determine the price range of the listing based on its rate
          const priceRangeFactor =
            (listing.nightlyRate.amount / listing.lengthOfStay - minRate) /
            rangeFactor;
          let priceRangeClass;
          if (priceRangeFactor >= 0.5) {
            priceRangeClass = "high-price";
          } else if (priceRangeFactor >= 0.25) {
            priceRangeClass = "medium-price";
          } else {
            priceRangeClass = "low-price";
          }

          return (
            <a
              href={listing.listingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={`listing-card`}
              key={index}
            >
              <div key={index}>
                <h2 className="listing-name">{listing.name}</h2>
                <div className="listing-details">
                  <div className="bedrooms">
                    <span className="label">Bedrooms:</span>
                    <span className="value">{listing.bedrooms}</span>
                  </div>
                  <div className="rate">
                    <span className="label">Nightly Rate:</span>
                    <span className={`value ${priceRangeClass}`}>
                      $
                      {Math.ceil(
                        listing.nightlyRate.amount / listing.lengthOfStay
                      )}
                    </span>
                  </div>
                  <div className="rating">
                    <span className="label">Rating:</span>
                    <span className="value">
                      <img
                        className="star-rating"
                        src={starRating}
                        alt="star_rating"
                      />
                      <span>{listing.rating ? listing.rating : "N/A"}</span>
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="listing-image">
                  <img src={listing.images[0]} alt={listing.name} />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </>
  );
}

export default SearchResults;
