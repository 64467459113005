import { useState } from "react";
import filterImage from "./../images/filter.svg";
import "./Filter.css";

function Filter({ amenities, onFilterChange, exclude, setExclude }) {
  const [checkedAmenities, setCheckedAmenities] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const handleCheckboxChange = (e) => {
    const amenityId = Number(e.target.value);
    let updatedAmenities;

    if (e.target.checked) {
      updatedAmenities = [...checkedAmenities, amenityId];
    } else {
      updatedAmenities = checkedAmenities.filter((id) => id !== amenityId);
    }
    setCheckedAmenities(updatedAmenities);
    onFilterChange(updatedAmenities);
  };

  return (
    <div className="filter-container">
      <div className="filter-card" onClick={() => setShowFilters(!showFilters)}>
        <p className="filter-header">Filters</p>
        <img width={"14px"} src={filterImage} alt="filter search results" />
      </div>
      {showFilters && (
        <div className="filter-form">
          <div className="filter-section">
            <h3 className="filter-subheader">Amenities</h3>
            {amenities.map((amenity) => (
              <div key={amenity.id} className="filter-option">
                <input
                  type="checkbox"
                  id={amenity.id}
                  name={amenity.name}
                  value={amenity.id}
                  checked={checkedAmenities.includes(amenity.id)}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={amenity.id}>{amenity.name}</label>
              </div>
            ))}
          </div>
          <div className="filter-section">
            <h3 className="filter-subheader">Exclude</h3>
            <div className="filter-option">
              <input
                type="checkbox"
                id="exclude"
                name="exclude"
                value="exclude"
                checked={exclude}
                onChange={() => {
                  setExclude(!exclude);
                }}
              />
              <label htmlFor="exclude">Exclude amenities</label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Filter;
