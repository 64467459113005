import axios from "axios";

export function fetchAllAirbnbByLocation(
  location,
  checkin,
  checkout,
  adults,
  page,
  minBedrooms
) {
  const promises = [];

  for (let i = 1; i < 8; i++) {
    const options = {
      method: "GET",
      url: "https://airbnb13.p.rapidapi.com/search-location",
      params: {
        location: location,
        checkin: checkin,
        checkout: checkout,
        adults: adults,
        page: i,
      },
      headers: {
        "X-RapidAPI-Key": "d027337b49msh690c316be046467p1dfe4djsnfe500b8faef2",
        "X-RapidAPI-Host": "airbnb13.p.rapidapi.com",
      },
    };

    promises.push(
      axios
        .request(options)
        .then(function (response) {
          const data = response.data;
          const simplifiedData = simplifyData(
            data,
            minBedrooms,
            checkin,
            checkout
          );
          return simplifiedData;
        })
        .catch(function (error) {
          console.error(error);
        })
    );
  }

  return Promise.all(promises).then(function (results) {
    const mergedResults = results.reduce(
      function (acc, curr) {
        acc.error = acc.error || curr.error;
        acc.headers = acc.headers || curr.headers;
        acc.results = acc.results.concat(curr.results);
        return acc;
      },
      { error: null, headers: null, results: [] }
    );
    return mergedResults;
  });
}

export function fetchPagedAirbnbByLocation(
  location,
  checkin,
  checkout,
  adults,
  page,
  minBedrooms
) {
  const options = {
    method: "GET",
    url: "https://airbnb13.p.rapidapi.com/search-location",
    params: {
      location: location,
      checkin: checkin,
      checkout: checkout,
      adults: adults,
      page: page,
    },
    headers: {
      "X-RapidAPI-Key": "d027337b49msh690c316be046467p1dfe4djsnfe500b8faef2",
      "X-RapidAPI-Host": "airbnb13.p.rapidapi.com",
    },
  };

  return axios
    .request(options)
    .then(function (response) {
      const data = response.data;
      const simplifiedData = simplifyData(data, minBedrooms, checkin, checkout);
      return simplifiedData;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export function simplifyData(response, minBedrooms, checkin, checkout) {
  const simplifiedResults = response.results
    .filter((result) => result.bedrooms <= minBedrooms)
    .map((result) => {
      const lengthOfStay = getLengthOfStay(checkin, checkout);
      const { name, bedrooms, price, deeplink, amenityIds, images, rating } =
        result;

      const nightlyRate = {
        title: price.priceItems[0].title,
        amount: price.priceItems[0].amount,
      };
      const { rate } = price;
      return {
        name,
        bedrooms,
        rate,
        listingUrl: deeplink,
        amenityIds,
        nightlyRate,
        lengthOfStay,
        images,
        rating,
      };
    });
  return {
    error: response.error,
    headers: response.headers,
    results: simplifiedResults,
  };
}

export function getLengthOfStay(checkin, checkout) {
  const checkinDate = new Date(checkin);
  const checkoutDate = new Date(checkout);

  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day

  const diffInTime = checkoutDate.getTime() - checkinDate.getTime(); // Difference in milliseconds
  const diffInDays = Math.round(diffInTime / oneDay); // Difference in days, rounded to the nearest whole number

  return diffInDays;
}
