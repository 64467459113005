import React from "react";
import "./Header.css";

function Header() {
  return (
    <div className="header">
      <div className="title">
        <h1 className="logo">AirSupply</h1>
      </div>
    </div>
  );
}

export default Header;
